import React, { useState } from "react";
import { Card, CardContent, TextField, Button, Typography } from "@mui/material";
import { Email as EmailIcon, Person as PersonIcon, Message as MessageIcon } from "@mui/icons-material";
import { Instagram as InstagramIcon, LinkedIn as LinkedInIcon, Telegram as TelegramInIcon, Email as EmailInIcon } from '@mui/icons-material';
import styled from "styled-components";
import emailjs from "emailjs-com";

const ContactContainer = styled.div`
    max-width: 600px;
    margin: 0 auto;
    margin-top: 120px;
    text-align: justify;
    @media (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
      }
`;

const StyledCard = styled(Card)`
  padding: 20px;
  background: #f7f7f7; // Couleur de fond de la carte
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Ombre pour la carte
`;

const Title = styled.h1`
    color: #333;
    font-size: 2.5rem;
    margin-top: 0px;
    margin-bottom: 20px;
`;

const TitleReseaux = styled.h2`
    color: #333;
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SocialMediaIcon = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  color: inherit;
  text-decoration: none;
  &:hover {
    color: #0072b1; // Couleur lors du survol
  }
`;

const IconLabel = styled(Typography)`
  margin-top: 5px;
`;

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      await emailjs.sendForm("service_58ffkzo", "template_95p1inb", e.target, "2H6D_y2R7lZS8rRMk");
      console.log("Form submitted successfully");
      setFormData({
        name: "",
        email: "",
        message: ""
      });
      setSubmitting(false);
      
    } catch (error) {
      console.error("Form submission failed:", error);
      setError("An error occurred. Please try again later."); // Set error message
      setSubmitting(false);
    }
  };

  return (
    <ContactContainer>
      <StyledCard>
        <CardContent>
          <Title style={{ textAlign: 'center'}}>
            Contact Us
          </Title>
          <form onSubmit={handleSubmit}>
            <TextField
              InputProps={{
                startAdornment: <PersonIcon />
              }}
              label="Your Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              InputProps={{
                startAdornment: <EmailIcon />
              }}
              label="Your Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <TextField
              InputProps={{
                startAdornment: <MessageIcon />
              }}
              label="Your Message"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            {error && <Typography color="error">{error}</Typography>}
            
            <ButtonContainer>
              <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                {submitting ? "Submitting..." : "Send Message"}
              </Button>
            </ButtonContainer>
          </form>
        </CardContent>
      </StyledCard>

      <TitleReseaux align="left" gutterBottom>
        Follow us!
      </TitleReseaux>
      <SocialMediaContainer>
        <SocialMediaIcon href="https://www.instagram.com/shalom_epfl/" target="_blank">
          <InstagramIcon fontSize="large" style={{ fontSize: 75 }} />
          <IconLabel>Instagram</IconLabel>
        </SocialMediaIcon>
        <SocialMediaIcon href="https://www.linkedin.com/company/shalom-epfl/" target="_blank">
          <LinkedInIcon fontSize="large" style={{ fontSize: 75 }} />
          <IconLabel>Linkedin</IconLabel>
        </SocialMediaIcon>
        <SocialMediaIcon href="https://t.me/shalomepfl" target="_blank">
          <TelegramInIcon fontSize="large" style={{ fontSize: 75 }} />
          <IconLabel>Télégram</IconLabel>
        </SocialMediaIcon>
        <SocialMediaIcon href="mailto:shalom@epfl.ch">
          <EmailInIcon fontSize="large" style={{ fontSize: 75 }} />
          <IconLabel>shalom@epfl.ch</IconLabel>
        </SocialMediaIcon>
      </SocialMediaContainer>
    </ContactContainer>
  );
};

export default ContactUs;
