import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 120px;
    text-align: justify;
    @media (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
      }
`;

const Title = styled.h1`
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: left;
`;

const Description = styled.p`
    color: #555;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
`;

const Image = styled.img`
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
    color: #333;
    font-size: 1.4rem;
    margin-bottom: 10px;
`;

const MessageContainer = styled.div`
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
`;

const MessageText = styled.p`
    color: #333;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 10px;
`;

const MessageAuthor = styled.p`
    color: #666;
    font-style: italic;
`;

const ActivityDetails = () => {
    const { id } = useParams();

    const activity = {
        id: id,
        title: "Creation of the Association",
        description: "SHALOM - the Israeli  Association  has been established! We are excited to develop it further and create enriching experiences and opportunities for Israeli students, as well as individuals interested in Israeli culture.",
        imageUrl: process.env.PUBLIC_URL + "/events/creation.png",
        presidentMessage: {
            text: "Welcome to the new Israeli Association at EPFL! We are thrilled to welcome new members to our community. Together, we aim to explore and celebrate Israeli culture and innovations.",
            author: "Yshai Dinée-Baumgarten"
        },
        treasurerMessage: {
            text: "I am excited to be part of this new adventure and to contribute to the association's development.",
            author: "Solal Ben Hamou"
        },
        memberMessage: {
            text: "I am looking forward to the upcoming events and activities. It's great to be part of this community!",
            author: "Yanaël Grundland"
        }

    };

    return (
        <Container>
            <Title>{activity.title}</Title>
            <Description>{activity.description}</Description>
            {<Image src={activity.imageUrl} alt={activity.title} />}

            <SectionTitle>Reactions</SectionTitle>
            <MessageContainer>
                <MessageText>{activity.presidentMessage.text}</MessageText>
                <MessageAuthor>- {activity.presidentMessage.author}</MessageAuthor>
            </MessageContainer>
            <MessageContainer>
                <MessageText>{activity.treasurerMessage.text}</MessageText>
                <MessageAuthor>- {activity.treasurerMessage.author}</MessageAuthor>
            </MessageContainer>
            <MessageContainer>
                <MessageText>{activity.memberMessage.text}</MessageText>
                <MessageAuthor>- {activity.memberMessage.author}</MessageAuthor>
            </MessageContainer>
        </Container>
    );
};

export default ActivityDetails;