import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";

const FooterContainer = styled.footer`
    background-color: #f8f9fa;
    padding: 0px 0; /* Adjust top and bottom padding as needed */
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0; /* Add this to ensure it aligns correctly on the left */
    z-index: 1000; /* Ensure the footer is above other content */
`;


const Footer = () => {

    return (
        <FooterContainer>
            <Typography variant="body2" align="center" color="textSecondary">
                    &copy; {new Date().getFullYear()} SHALOM - The Israeli Association. All rights reserved. Association recognized by EPFL.
            </Typography>
        </FooterContainer>
    );
};

export default Footer;