import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 120px;
  text-align: justify;
  @media (max-width: 600px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Title = styled.h1`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: left;
`;

const Description = styled.p`
  color: #555;
  font-size: 1.1rem;
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const ImageGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Space between images */
  flex-wrap: wrap; /* Allow images to wrap on small screens */
`;

const ImageFrame = styled.div`
  width: 300px; /* Fixed width */
  height: 400px; /* Fixed height */
  margin-bottom: 20px;
  border: 2px solid #ddd; /* Add frame around images */
  border-radius: 8px;
  overflow: hidden; /* Ensures image fits within the frame */
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Crops the image to fit */
`;

const SplitFrame = styled.div`
  display: flex;
  flex-direction: column; /* Stack images vertically */
  height: 400px; /* Same fixed height as the other frames */
`;

const SmallImageFrame = styled.div`
  flex: 1;
  height: 50%; /* Each image takes up half the height */
  border: 2px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
`;

const Evenement = () => {
  const { id } = useParams();

  // Dummy data for activity details (replace with actual data fetching)
  const evenement = {
    id: id,
    title: "Welcome Apero",
    description: "It was a real pleasure meeting you all! We hope you enjoyed the evening as much as we did.",
    ImageURL1: process.env.PUBLIC_URL + "/apero1.JPG",
    ImageURL2: process.env.PUBLIC_URL + "/apero2.HEIC",
    ImageURL3: process.env.PUBLIC_URL + "/apero3.JPG",
    ImageURL4: process.env.PUBLIC_URL + "/apero4.JPG", // New small image
  };

  return (
    <Container>
      <Title>{evenement.title}</Title>
      <Description>{evenement.description}</Description>

      {/* Images displayed in a grid layout */}
      <ImageGrid>

      <ImageFrame>
          <Image src={evenement.ImageURL3} alt={evenement.title} />
        </ImageFrame>

        {/* Split one frame into two smaller images stacked horizontally */}
        <ImageFrame>
          <SplitFrame>
            <SmallImageFrame>
              <Image src={evenement.ImageURL1} alt={evenement.title} />
            </SmallImageFrame>
            <SmallImageFrame>
              <Image src={evenement.ImageURL4} alt={evenement.title} />
            </SmallImageFrame>
          </SplitFrame>
        </ImageFrame>

        <ImageFrame>
          <Image src={evenement.ImageURL2} alt={evenement.title} />
        </ImageFrame>
        
      </ImageGrid>
    </Container>
  );
};

export default Evenement;
