import React from "react";
import { Grid, Card, CardMedia, CardContent, Typography, IconButton } from "@mui/material";
import styled from "styled-components";
import LinkedInIcon from '@mui/icons-material/LinkedIn'; // Import LinkedIn Icon from MUI

const ComiteeContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 120px;
    @media (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
    }
`;

const All = styled.h1`
  margin-top: 120px;
`;

//const SHALOM_logo = `${process.env.PUBLIC_URL}/SHALOM_logo.svg`; // Chemin vers le logo
const picture_prefix = `${process.env.PUBLIC_URL}/photoDuComite/`;

const MemberCard = styled(Card)`
  min-height: 330px;
  max-width: 345px;
  margin: 20px;
`;

const MemberRole = styled(Typography)`
  margin-top: 12px;
  font-weight: bold;
`;

const MemberNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MemberName = styled(Typography)`
  color: #555; // Vous pouvez ajuster la couleur
  margin-right: 8px;
`;

const Title = styled.h1`
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: left;
`;

const LinkedInButton = styled(IconButton)`
  margin-left: 8px;
`;

const members = [
    { name: "Yshai Dinée-Baumgarten", role: "President", image: picture_prefix + `Yshai.jpeg`, linkedin: "https://www.linkedin.com/in/yshai-db/" },
    { name: "Raphaël Benichou", role: "Vice-President", image: picture_prefix + `Raphael.jpeg`, linkedin: "https://www.linkedin.com/in/rapha%C3%ABl-benichou/" },
    { name: "Solal Ben Hamou", role: "Treasurer", image: picture_prefix + `Solal.jpeg`, linkedin: "https://www.linkedin.com/in/solal-benhamou-8394b2242/" },
  { name: "Andy Bittan", role: "Events Manager", image: picture_prefix + `Andy.jpeg`, linkedin: "#" },
    { name: "", role: "General Secretary", image: picture_prefix + `Elior.jpeg`, linkedin: "#"},
    { name: "", role: "Logistic Manager", image: picture_prefix + `Ethan.jpeg`, linkedin: "#" },
    { name: "Nathan Schachne", role: "Outreach Manager", image: picture_prefix + `Nathan.jpeg`, linkedin: "#" },
    { name: "Gabriel Taieb", role: "IT Manager", image: picture_prefix + `Gabriel.jpeg`, linkedin: "https://www.linkedin.com/in/gabriel-taieb-812798227/" },
    { name: "Yael Soussan", role: "Communication Manager", image: picture_prefix + `Yael.jpeg`, linkedin: "https://www.linkedin.com/in/ya%C3%ABl-soussan-b66418306/" },
    { name: "Yanael Grundland", role: "Communication Manager", image: picture_prefix + 'Yanael.jpeg', linkedin: "https://www.linkedin.com/in/yana%C3%ABl-grundland-99304630a/" },
];

const ComiteeMembers = () => {
    return (
      <All>
        <ComiteeContainer maxWidth="md" style={{ marginTop: '100px' }}>
          <Title variant="h4" gutterBottom component="div">
            Comitee Members
          </Title>
          <Grid container spacing={4} justifyContent="center">
            {members.map((member, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <MemberCard>
                  <CardMedia
                    component="img"
                    height="300"
                    image={member.image}
                    alt={`${member.role}: ${member.name}`}
                    style={{objectPosition: 'center' }}
                  />
                  <CardContent>
                    <MemberRole gutterBottom variant="h6" component="div">
                      {member.role}
                    </MemberRole>
                    <MemberNameContainer>
                      <MemberName color="textSecondary">
                        {member.name}
                      </MemberName>
                      {member.name && member.linkedin && member.linkedin !== "#" && (
                        <LinkedInButton 
                          aria-label="linkedin" 
                          component="a" 
                          href={member.linkedin} 
                          target="_blank"
                        >
                          <LinkedInIcon />
                        </LinkedInButton>
                      )}
                    </MemberNameContainer>
                  </CardContent>
                </MemberCard>
              </Grid>
            ))}
          </Grid>
        </ComiteeContainer>
      </All>
    );
};

document.body.style.paddingBottom = "3cm";
document.body.style.paddingTop = "0.5cm";

export default ComiteeMembers;
