// src/pages/about/BecomeAMember.js
import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';

const MembershipContainer = styled.div`
  max-width: 1000px;
  margin: 50px auto;
  margin-top: 120px;
  text-align: justify;
  @media (max-width: 600px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Title = styled.h1`
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
`;

const Description = styled.p`
    color: #555;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const BecomeAMember = () => {
  return (
    <MembershipContainer>
      <Title>
        Become a Member
      </Title>
      <Description variant="h5" component="p" gutterBottom>
        Join SHALOM and be part of a community that celebrates culture, fosters friendships, and creates opportunities.
      </Description>
      <Description variant="h5" component="p" gutterBottm>
        For more information, please click on the link
      </Description>
      <ButtonContainer>
        <Button
          variant="contained"
          color="primary"
          href="https://forms.gle/GMV7TAnAtgbyrhot8"
          target="_blank"
          rel="noopener"
        >
          Sign Up
        </Button>
      </ButtonContainer>
    </MembershipContainer>
  );
};

export default BecomeAMember;
