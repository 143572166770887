import React from "react";
import { Container, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { motion } from "framer-motion";

const svg1 = process.env.PUBLIC_URL + "/assets/team.svg";
const svg2 = process.env.PUBLIC_URL + "/assets/tech.svg";
const svg3 = process.env.PUBLIC_URL + "/assets/world.svg";

// Styled component for the hero section
const AcceuilContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding-top: 100px; /* Adjusted to account for the header */
  padding-bottom: 50px;
`;

// Framer Motion variants for animations
const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1.5 } },
};

const titleVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 1.2, ease: "easeInOut" } },
  hover: { y: -5, transition: { duration: 0.3, ease: "easeInOut" } },
};

const textVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 1.2, ease: "easeInOut" } },
};

const buttonVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 1.2, ease: "easeInOut" } },
};

const svgVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 1, ease: "easeInOut" } },
};

// Styled component for the button
const StyledButton = styled(Button)`
  background-color: #003366;
  color: #fff;
  &:hover {
    background-color: #ffd700; /* Secondary color */
    color: #024A9F;
  }
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
`;

// Styled components for SVG illustrations with text
const SVGContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
  text-align: left;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const SVGImage = styled(motion.img)`
  width: 300px; /* Increased size */
  height: auto;
  position: absolute;
  left: -150px; /* Moved further to the left */

  @media (max-width: 768px) {
    position: relative;
    left: 0;
    margin-bottom: 20px;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  margin-left: 250px; /* Adjusted to align text properly */

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 0 20px;
  }
`;

const Home = () => {
  return (
    <AcceuilContainer as={motion.div} variants={containerVariants} initial="hidden" animate="visible">
      <Container maxWidth="md">
        <motion.h1
          variants={titleVariants}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          style={{ color: '#024A9F', fontSize: '3rem', marginBottom: '20px' }}
        >
          SHALOM ! 🇮🇱
        </motion.h1>
        
        <SVGContainer>
          <SVGImage src={svg1} alt="Teamwork" variants={svgVariants} initial="hidden" animate="visible" />
          <TextContainer>
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              style={{ color: '#666', fontSize: '1.2rem', lineHeight: '1.4', margin: '20px 0' }}
            >
              SHALOM - EPFL's Israeli Association is a gateway to the Israeli cultural and technological environment.
            </motion.p>
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              style={{ color: '#666', fontSize: '1.2rem', lineHeight: '1.4', margin: '20px 0' }}
            >
              The aim of the association is to create a community of students interested in Israeli culture and sharing similar values.
            </motion.p>
          </TextContainer>
        </SVGContainer>
        <SVGContainer>
          <SVGImage src={svg2} alt="Technology" variants={svgVariants} initial="hidden" animate="visible" />
          <TextContainer>
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              style={{ color: '#666', fontSize: '1.2rem', lineHeight: '1.4', margin: '20px 0' }}
            >
              You'll have access to a wide range of Israeli cultural activities, from traditional holiday celebrations to film screenings and seminars on Israeli history and culture. These events are a chance to deepen your understanding and appreciation of Israeli culture.
            </motion.p>
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              style={{ color: '#666', fontSize: '1.2rem', lineHeight: '1.4', margin: '20px 0' }}
            >
              We also organize team-building activities designed to strengthen the bonds between members and create a close-knit, dynamic community.
            </motion.p>
          </TextContainer>
        </SVGContainer>
        <SVGContainer>
          <SVGImage src={svg3} alt="Global Connection" variants={svgVariants} initial="hidden" animate="visible" />
          <TextContainer>
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              style={{ color: '#666', fontSize: '1.2rem', lineHeight: '1.4', margin: '20px 0' }}
            >
              You'll also have the opportunity to meet and exchange ideas with influential players in the Israeli technology sector, entrepreneurs and engineers, at our events.
            </motion.p>
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              style={{ color: '#666', fontSize: '1.2rem', lineHeight: '1.4', margin: '20px 0' }}
            >
              We aim to give members the opportunity to travel to Israel through an organized program. This trip will not only expose you to the country's rich history and culture, but also plunge you into the heart of the start-up nation's technology industry.
            </motion.p>
          </TextContainer>
        </SVGContainer>
        <Box>
          <motion.div variants={buttonVariants} initial="hidden" animate="visible">
            <StyledButton type="submit" variant="contained" size="large" component={Link} to="/become-a-member">
              Become a Member
            </StyledButton>
          </motion.div>
        </Box>
      </Container>
    </AcceuilContainer>
  );
};

document.title = "SHALOM - EPFL's Israeli Association";

export default Home;
