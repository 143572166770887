import React from "react";
import { Container } from "@mui/material";
import styled, { createGlobalStyle } from "styled-components";

const Title = styled.h1`
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
    margin-top: 120px;
`;

const Description = styled.p`
    color: #555;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
`;

// Utiliser styled-components pour des styles responsifs
const ResponsiveIframe = styled.iframe`
    width: 100%;
    height: 1200px; // hauteur par défaut pour les grands écrans
    overflow: auto; // permet le défilement à l'intérieur de l'iframe

    @media (max-width: 768px) {
        height: 1200px; // augmenter la hauteur sur les appareils mobiles pour une meilleure visibilité
        overflow: auto; // permet le défilement à l'intérieur de l'iframe
    }
`;

// Ajouter des styles CSS globaux pour s'assurer que l'iframe est défilable
const GlobalStyle = createGlobalStyle`
    iframe {
        -webkit-overflow-scrolling: touch; // permet un défilement fluide sur les appareils iOS
    }
`;

const Statuts = () => {
    return (
        <>
            <GlobalStyle />
            <Container maxWidth="md">
                <Title>Chart</Title>
                <Description variant="body1" paragraph>
                    Below is the updated chart of the association
                    from the General Assembly of April 19th 2024:
                </Description>
                <ResponsiveIframe 
                src={process.env.PUBLIC_URL + "/SHALOM_statuts.pdf"}
                ></ResponsiveIframe>
            </Container>
        </>
    );
};

document.body.style.paddingBottom = "3cm";

export default Statuts;
