import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 120px;
    text-align: justify;
    @media (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
      }
`;

const Title = styled.h1`
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: left;
`;

const Description = styled.p`
    color: #555;
    font-size: 1.1rem;
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 20px;
`;

const Image = styled.img`
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
`;

const Evenement = () => {
    const { id } = useParams();

    // Dummy data for activity details (replace with actual data fetching)
    const evenement = {
        id: id,
        title: "The first event of our association",
        description: "We are thrilled to announce that we will have our very first association booth at Vivapoly! We can't wait to meet everyone and share our mission and activities with you. Come visit us and be part of this exciting journey!",
        ImageURL: process.env.PUBLIC_URL +"/vivapoly.jpg",
    };

    return (
        <Container>
            <Title>{evenement.title}</Title>
            <Description>{evenement.description}</Description>
            <Image src={evenement.ImageURL} alt={evenement.title}/>
        </Container>
    );
};

export default Evenement;
