import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Menu, MenuItem, IconButton, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} component={Link} to="/about-us">About Us</MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/become-a-member">Become a Member</MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/activities">Events</MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/contact">Contact Us</MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/statuts">Chart</MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/comitee-members">Comitee Members</MenuItem>
      
    </Menu>
  );

  const buttonStyle = {
    margin: '0 10px',
    padding: '10px 20px',
  };

  return (
    <AppBar position="fixed" sx={{ bgcolor:"#024A9F" }}>
      <Toolbar style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" component={Link} to="/" style={{ display: 'flex', alignItems: 'center' }}>
          <img src={process.env.PUBLIC_URL + "/SHALOM_logo_white.svg"} alt="Your Logo" style={{ height: 'auto', width: 'auto', maxWidth: 100, maxHeight: 100 }} />
        </Typography>
        {isMobile ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleMobileMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <Box display="flex" justifyContent="flex-start">
            <Button style={buttonStyle} component={Link} to="/activities" color="inherit">Events</Button>
            <Box>
              <Button
                style={buttonStyle}
                aria-controls="about-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
                color="inherit"
                endIcon={<ArrowDropDownIcon />}>
                About
              </Button>
              <Menu
                id="about-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}>
                <MenuItem onClick={handleMenuClose} component={Link} to="/">About Us</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/become-a-member">Become a Member</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/statuts">Chart</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/comitee-members">Comitee Members</MenuItem>
              </Menu>
            </Box>
            <Button style={buttonStyle} component={Link} to="/contact" color="inherit">Contact Us</Button>
          </Box>
        )}
      </Toolbar>
      {renderMobileMenu}
    </AppBar>
  );
};

export default Navbar;