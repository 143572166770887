import React from "react";
import { Grid, Card, CardContent, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 120px;
  @media (max-width: 600px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const PublishDate = styled(Typography)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-style: italic;
`;

const StyledCard = styled(Card)`
  position: relative;
  margin-bottom: 20px;
  height: 100%; // Ensure cards are the same height
  display: flex;
  flex-direction: column; // Ensure content grows vertically
  transition: transform 0.3s, box-shadow 0.3s; // Add transition for smooth effect

  &:hover {
    transform: translateY(-3px); // Slightly raise the card on hover
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); // Add shadow on hover
  }
`;

const Title = styled.h1`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
  margin-top: 120px;
  text-align: left;
`;

const SubTitle = styled.h1`
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: left;
`;

const CardButton = styled(Button)`
  margin-top: auto;
  marginLeft: auto;

`;

const Activities = () => {
  const creationPublishDate = "08/04/2024";
  const vivapPublishDate = "20/05/2024";
  const semaineDesAssosPublishDate = "24/09/2024";
  const welcomeAperoPublishDate = "19/09/2024";

  const creationActivity = {
    title: "Creation of the Association",
    description: "SHALOM - the Israeli Association has been established! We are excited to develop it further and create enriching experiences and opportunities for Israeli students, as well as individuals interested in Israeli culture.",
    link: "/creation",
  };

  const vivapEvent = {
    title: "Vivapoly 2024",
    description: "We are happy to announce that we will take part in the 2024 Vivapoly edition! Come and see us at our stand!",
    link: "/vivapEvent",
  };

  const semaineDesAssosEvent = {
    title: "Semaine des associations 2024",
    description: "We’re thrilled to be part of \"EPFL Associations Week 2024\"! Stop by our stand to discover more about what we do and get involved with our community!",
    link: "/semaineDesAssos",
  };

  const welcomeAperoEvent = {
    title: "Welcome Apero",
    description: "The perfect way to meet the new members and create a sense of community!",
    link: "/welcomeApero",
  };

  return (
    <Container maxWidth="lg">
      <Title>Associative Life</Title>

      <Grid container spacing={2}>
        {/* Semaine des associations card */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <SubTitle>{semaineDesAssosEvent.title}</SubTitle>
              <Typography variant="body1" color="textSecondary" paragraph>
                {semaineDesAssosEvent.description}
              </Typography>
              <PublishDate variant="caption" color="textSecondary">
                {semaineDesAssosPublishDate}
              </PublishDate>
              <CardButton variant="contained" color="primary" component={Link} to={semaineDesAssosEvent.link}>
                Learn More
              </CardButton>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Welcome Apero card */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <SubTitle>{welcomeAperoEvent.title}</SubTitle>
              <Typography variant="body1" color="textSecondary" paragraph>
                {welcomeAperoEvent.description}
              </Typography>
              <PublishDate variant="caption" color="textSecondary">
                {welcomeAperoPublishDate}
              </PublishDate>
              <CardButton variant="contained" color="primary" component={Link} to={welcomeAperoEvent.link}>
                Learn More
              </CardButton>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Vivapoly event card */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <SubTitle>{vivapEvent.title}</SubTitle>
              <Typography variant="body1" color="textSecondary" paragraph>
                {vivapEvent.description}
              </Typography>
              <PublishDate variant="caption" color="textSecondary">
                {vivapPublishDate}
              </PublishDate>
              <CardButton variant="contained" color="primary" component={Link} to={vivapEvent.link}>
                Learn More
              </CardButton>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Creation activity card */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <SubTitle>{creationActivity.title}</SubTitle>
              <Typography variant="body1" color="textSecondary" paragraph>
                {creationActivity.description}
              </Typography>
              <PublishDate variant="caption" color="textSecondary">
                {creationPublishDate}
              </PublishDate>
              <CardButton variant="contained" color="primary" component={Link} to={creationActivity.link}>
                Learn More
              </CardButton>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Activities;
